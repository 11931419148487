import jQuery from "jquery";
import popper from "popper.js";

window.onload = () => {
    preSets();

    d3.selectAll('.portfolio_action_box svg')
        .on('click', function(){
            let flag = d3.select(this).attr('data-set');
            if(flag === 'false'){
                let position = d3.select(this).attr('data-position');
                d3.selectAll('.portfolio_action_box svg').attr('data-set', 'false');
                d3.selectAll('.portfolio_action_box svg').selectAll('rect').attr('fill', '#fff');
                d3.select(this).attr('data-set', 'true');
                d3.select(this).selectAll('rect')
                    .attr('fill', '#2ac6ff');
                if(position === 'cube'){
                    d3.selectAll('.example_box')
                        .transition().duration(300)
                        .style('width', '47%');
                    d3.selectAll('.example_box a')
                        .transition().duration(300)
                        .style('font-size', '2.1vw');
                }
                if(position === 'rect'){
                    d3.selectAll('.example_box')
                        .transition().duration(300)
                        .style('width', '30%');
                    d3.selectAll('.example_box a')
                        .transition().duration(300)
                        .style('font-size', '1.8vw');
                }
            }
        });

       
      

    function preSets(){
        if(window.innerWidth > 760){
        d3.selectAll('.example_box')
            .style('width', '47%');
        d3.selectAll('.example_box a')
            .style('font-size', '2.1vw');
        }
    }

}